export const Image = (props: {
  src: string;
  alt: string;
  height?: number;
  width?: number | string;
  style?: React.CSSProperties;
  className?: string;
  loading?: "lazy" | "eager";
  onClick?: () => unknown;
}) => {
  // eslint-disable-next-line @next/next/no-img-element
  return <img alt="" {...props} />;
};
